@import "../../styles/variables";
@import "../../styles/mixins";

.System {
    width: 80%;
    border: 0.2px solid gray;
    padding: 30px 0px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    background-image: url('../../icons/brick.svg');
    background-repeat: repeat;

    .brick-wall {
        line {
            stroke: lightgrey;
        }
    }

    .table {
        margin-top: -130px;
        margin-bottom: -240px;
        width: 872px;
        height: 486px;
    }
}