@import "./styles/variables";
@import "./styles/mixins";

::-webkit-scrollbar {
  display: none;
}

.app {
  display: flex;
  flex-flow: column;
  align-items: center;
  min-height: 100vh;
  overflow-x: hidden;
  font-size: $default-font-size;
  scroll-behavior: smooth;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &.dark {
    background-color: $background-color-dark;
  }
}

.app-header {
  margin-bottom: 50px;
  padding: 5px;
  width: 100%;

  .btn-menu {
    border: 0;
    outline: none;
    margin: 5px;
    position: absolute;
    z-index: 1000;
    @include easeOut;

    .btn-line {
      width: 20px;
      height: 3px;
      margin: 0 0 5px 0;
      background-color: black;
      @include easeOut;

      &.dark {
        background-color: lightgrey;
      }
    }

    // Rotate Into X With Menu Lines
    &.close {
      transform: rotate(180deg);
      position: fixed;

      .btn-line {
        // Line 1 - Rotate
        &:nth-child(1) {
          transform: rotate(45deg) translate(5px, 5px);
        }

        // Line 2 - Hide
        &:nth-child(2) {
          opacity: 0;
        }

        // Line 3 - Rotate
        &:nth-child(3) {
          transform: rotate(-45deg) translate(7px, -6px);
        }
      }
    }
  }
}

a {
  &:focus,
  &:hover,
  &:visited,
  &:link {
    color: inherit;
    text-decoration: none;
    background: none;
    border: none;
  }
}

.toggle-theme-button {
  width: 25px;
  height: 25px;
  display: block;
  position: relative;
  border: none;

  svg {
    @include easeOut(0.2s);
    &.light {
      fill: gray;
      stroke: white;
    }
    &.dark {
      fill: #333;
      stroke: white;
      stroke-width: 0.5;
      transform: rotate(180deg);
    }
  }
}

.app-navigation {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 5px;

  .app-controls {
  }

  .home-menu {
    display: flex;
    flex-flow: row;
    justify-content: center;
    list-style: none;
    font-size: 0.8rem;
    width: max-content;
    padding: 0;

    text-transform: uppercase;
    font-weight: lighter;

    li {
      display: flex;
      justify-content: center;
    }

    &__link {
      display: inline-block;
      padding: 10px 15px;
      width: 50px;
      text-align: center;

      &.dark {
        color: lightgrey;
      }

      &:hover {
        box-shadow: 0px 2px 0px 0px lightgreen;
      }

      &-active {
        box-shadow: 0px 2px 0px 0px darkgreen;
        color: darkgreen;
        font-weight: 500;
      }
    }
  }
}

// Drawer mode navigation menu
.app-navigation--drawer {
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
  height: 100vh;
  background-color: $primary-color;
  opacity: 0.8;
  @include easeOut(0.2s);

  &__show {
    visibility: visible;
    transform: translateX(0);
  }

  &__hide {
    visibility: hidden;
    transform: translateX(-100%);
  }

  .app-controls {
    width: 100%;
    display: flex;
    justify-content: center;
    .toggle-theme-button {
      &.light {
        fill: rgba(gray, 1);
      }
      &.dark {
        fill: rgba(#333, 1);
      }
    }
  }

  .home-menu {
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-top: 30%;
    list-style: none;
    font-size: 1rem;
    width: 100%;
    height: 30%;
    text-transform: uppercase;
    font-weight: lighter;
    color: white;
    padding: 0;

    li {
      width: 50%;
    }

    &__link {
      display: block;
      padding: 10px 0px;
      width: 100%;
      text-align: center;

      &:hover {
        box-shadow: 0px 2px 0px 0px green;
      }

      &-active {
        box-shadow: 0px 2px 0px 0px lightgreen;
        font-weight: 800;
      }
    }
  }
}

hr {
  margin: 0px;
}
