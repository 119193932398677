@mixin easeOut($delay: 0.5s) {
  transition: all $delay ease-out;
}

// Media Query Mixins

@mixin iphonex {
  @media screen and (max-width: 376px) {
    @content;
  }
}

@mixin mediaSm {
  @media screen and (max-width: 500px) {
    @content;
  }
}

@mixin mediaMd {
  @media screen and (max-width: 768px) {
    @content;
  }
}

@mixin mediaLg {
  @media screen and (min-width: 769px) and (max-width: 1170px) {
    @content;
  }
}

@mixin mediaXl {
  @media screen and (min-width: 1171px) {
    @content;
  }
}
