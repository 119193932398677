@import "../../styles/mixins";
.about {
  // width: 100%;
  display: flex;
  flex-flow: column;
  min-width: 600px;
  max-width: 600px;

  @include iphonex {
    min-width: 350px;
    max-width: 350px;
  }
  // justify-content: center;
  // align-items: center;

  &.dark {
    color: lightgray;
  }

  .heading {
    font-size: 12px;
    font-weight: lighter;
    text-transform: uppercase;
    margin: 10px 0;
  }

  .about-section {
    width: 100%;
    border-radius: 5px;
    display: flex;
    flex-flow: row;
    margin-bottom: 50px;

    @include iphonex {
      flex-flow: column;
    }

    .intro {
      flex-basis: 60%;
      margin-right: 20px;

      &__title {
        font-size: 24px;
        font-weight: lighter;
        border-radius: 5px;
        height: max-content;
        margin-bottom: 20px;

        a {
          &:hover {
            text-decoration: underline;
          }
        }
      }

      &__text {
        border-radius: 5px;
        height: max-content;
        margin-top: 10px;
      }
    }

    .images {
      display: flex;
      flex-flow: column;

      .profile-image {
        width: 300px;
        height: 375px;
      }

      @include iphonex {
        margin-top: 20px;

        .profile-image {
          width: 350px;
          height: 480px;
          text-align: center;
        }
      }

      .connect {
        display: flex;
        flex-flow: row;
        margin-top: 50px;
        display: flex;
        justify-content: center;

        &-icon {
          width: 26px;
          height: 26px;
          margin: 0 15px;
          border: none;
          cursor: pointer;
          background-repeat: no-repeat;

          &:hover {
            transform: scale(1.2);
            transition: all 0.1s;
          }

        }

        .insta {
          background-image: url(../../icons/dark/instagram.svg);
        }

        .linkedin {
          background-image: url(../../icons/dark/linkedin.svg);
        }

        .facebook {
          background-image: url(../../icons/dark/facebook.svg);
        }

        .twitter {
          background-image: url(../../icons/dark/twitter.svg);
        }

        .mail {
          background-image: url(../../icons/dark/email.svg);
        }

        &.dark {
          .insta {
            background-image: url(../../icons/light/instagram.svg);
          }

          .linkedin {
            background-image: url(../../icons/light/linkedin.svg);
          }

          .facebook {
            background-image: url(../../icons/light/facebook.svg);
          }

          .twitter {
            background-image: url(../../icons/light/twitter.svg);
          }

          .mail {
            background-image: url(../../icons/light/email.svg);
          }
        }
      }
    }
  }
}
