.snake-game {
  padding: 10px;
  //   border: 1px solid blue;
  @media screen and (max-width: 576px) {
    transform: scale(0.8);
  }

  &__info {
    color: white;
    display: flex;
    justify-content: space-between;
  }

  canvas {
    border: 1px solid green;
  }
}
