@import "../../styles/variables";
.game-console {
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-bottom: 20px;
  position: relative;

  &.dark {
    color: lightgray;
  }

  .snake-score {
    margin-top: -90px;
  }

  .note {
    margin-top: 30px; 
    font-style: italic;
  }

  .phone {
    position: absolute;
    top: 132px;
    left: 53px;
  }
  
  .console-screen {
    width: min-content;
    // height: 400px;
    background-color: black;
    border: 1px solid gray;
    margin: 20px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .updown {
    display: flex;
    flex-flow: column;
    width: 200px;
    height: 300px;

    .buttonset-up,.buttonset-down {
      flex-basis: 50%;
      width: 100%;
      border: 1px solid black;
    }
  }
  
  .dpad {
    width: 200px;
    height: 200px;
    
    & * {
      border: 1px solid black;
      position: relative;  
      background-color: $primary-color;
    }

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 5px 5px;
    grid-template-areas:
      ". dpad-up ."
      "dpad-left . dpad-right"
      ". dpad-down .";
  }
  
  .dpad-up { grid-area: dpad-up; 
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
  }
  
  .dpad-left { grid-area: dpad-left; 
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;}
  
  .dpad-right { grid-area: dpad-right; 
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
  }
  
  .dpad-down { grid-area: dpad-down; 
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;}
  
  @media all and (-ms-high-contrast:none) {
    .dpad {
      display: -ms-grid;
      -ms-grid-columns: repeat(3, 1fr);
      -ms-grid-rows: repeat(3, 1fr);
    }
  
    .dpad-up {
      -ms-grid-row: 1;
      -ms-grid-row-span: 1;
      -ms-grid-column: 2;
      -ms-grid-column-span: 1;
    }
  
    .dpad-left {
      -ms-grid-row: 2;
      -ms-grid-row-span: 1;
      -ms-grid-column: 1;
      -ms-grid-column-span: 1;
    }
  
    .dpad-right {
      -ms-grid-row: 2;
      -ms-grid-row-span: 1;
      -ms-grid-column: 3;
      -ms-grid-column-span: 1;
    }
  
    .dpad-down {
      -ms-grid-row: 3;
      -ms-grid-row-span: 1;
      -ms-grid-column: 2;
      -ms-grid-column-span: 1;
    }
  }
}