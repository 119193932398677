$primary-text-color: #444;
$primary-color: #444;
$secondary-text-color: white;
$secondary-color: #1f1f1f;
$default-font-size: 16px;
$mobile: 400px;
$mobile-landscape: 767px;
$tablet: 1170px;
$widescreen: 1171px;
$wood: #654321;
$wood-dark: #3f2a14;
$background-color-dark: #2b2b2b;