.keyboard {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  .keyboard-key {
    user-select: none;
  }

  @media screen and (max-width: 576px) {
    transform: scale(0.7);
  }

  .keyboard-svg{
    perspective: 300px;
  }

  #kbd {
    // margin-left: 20px;
    // transform: scale(0.8);
  // transform: rotateX(10deg);
  }

  .keyboard-key:hover {
    cursor: pointer;
    // @extend .backlit;
  }
}

.backlit {
  fill: #39ff96;
  color: red;
}

.typingArea {
  border: 2px solid green;
  width: 300px;
  min-width: 100px;
  min-height: 100px;
  background-color: black;
  color: #39ff96;
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif;
  margin: 10px;
  padding: 10px;
  text-align: left;
  overflow: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  pre {
      display: inline;
  }
  
  .cursorSpan {
    animation: blink-animation 1s steps(5, start) infinite;
  }
  @keyframes blink-animation {
    to {
      visibility: hidden;
    }
  }
}
