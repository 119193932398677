.monitor {
  width: 400px;
  margin-bottom: 30px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  color: black;
  z-index: 100;

  &.backlit {
    box-shadow: 0px 0px 50px 10px rgba(26, 240, 119, 1);
  }

  @media screen and (max-width: 576px) {
    transform: scale(0.8);
  }

  &__bezel {
    height: 200px;
    border: 5px solid gray;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    padding: 10px;
    background-color: black;

    .display {
      width: 100%;
      height: 100%;
      color: #39ff96;
      font-size: 12px;
      font-family: Arial, Helvetica, sans-serif;
      text-align: left;
      overflow-wrap: break-word;
      display: flex;

      .typing-area {
        max-width: 100%;
        max-height: 100%;
        overflow-y: scroll;
        
        &::-webkit-scrollbar {
          display: none;
        }

        pre {
          display: inline;
          max-width: 100%;
          max-height: 200px;
          overflow-wrap: break-word;
          white-space: pre-wrap;

        }

       
      }
    }

    .cursorSpan {
      float: right;
      animation: blink-animation 1s steps(5, start) infinite;
    }
    @keyframes blink-animation {
      to {
        visibility: hidden;
      }
    }
  }

  &__bezel-bottom {
    height: 10px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    background-color: gray;

    .led {
      margin-right: 10px;
      height: 5px;
      width: 10px;
      border: none;
      outline: none;

      &:hover {
        cursor: pointer;
      }

      &__on {
        background-color: #39ff96;
      }

      &__off {
        background-color: red;
      }
    }
  }
}
